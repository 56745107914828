import { Box } from '@chakra-ui/react';

export default function DataCardWithInfo({ label, value, desc }) {
  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Box p="4">
        <Box fontWeight="semibold" as="div" lineHeight="tight" isTruncated>
          {label}
        </Box>
        <Box as="div" fontWeight="bold" fontSize="4xl">
          {value}
        </Box>
        <Box as="div" color="gray.600" fontSize="sm">
          {desc}
        </Box>
      </Box>
    </Box>
  );
}
