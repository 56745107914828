import { SimpleGrid } from '@chakra-ui/react';
import DataCardWithInfo from '../common/DataCardWithInfo';

const currencyOptions = {
  style: 'currency',
  currency: 'INR',
};
const currencyFormatter = new Intl.NumberFormat('en-IN', currencyOptions);
const netPayout = currencyFormatter.format(5703.81);

const grossRevenue = currencyFormatter.format(7862.93);

export default function ZomatoDataGrid() {
  return (
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2} my="4">
      <DataCardWithInfo
        label={'Delivered Order(s)'}
        value={44}
        desc={`Total Orders Successfully Delivered by Zomato`}
      />
      <DataCardWithInfo
        label={'Merchant Cancelled Orders'}
        value={'N/A'}
        desc={`Total Orders Cancelled by Merchant`}
      />
      <DataCardWithInfo
        label={'Gross Revenue'}
        value={grossRevenue}
        desc={`Inclusive of GST liability of Merchant`}
      />
      <DataCardWithInfo
        label={'Net Payout'}
        value={netPayout}
        desc={`Inclusive of TDS TCS Platform Charges & Deductions (see breakdown)`}
      />
    </SimpleGrid>
  );
}
