import { Box } from '@chakra-ui/react';

export default function DataCardWithUnit({ label, value, unit = '' }) {
  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Box p="4">
        <Box fontWeight="semibold" as="div" lineHeight="tight" isTruncated>
          {label}
        </Box>
        <Box as="div" fontWeight="bold" fontSize="4xl">
          {value}{' '}
          <Box as="span" fontWeight="semibold" fontSize="md">
            {unit}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
