import { Center, CircularProgress } from '@chakra-ui/react';
import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Container from '../common/Container';
import HeadLine from '../common/HeadLine';
import NavBar from '../common/NavBar';
import DataBarChart from './DataBarChart';
import DataGrid from './DataGrid';
import FilterInfo from './FilterInfo';
import Filters from './Filters';
import SalesLineChart from './SalesLineChart';
import VizCard from './VizCard';

export default function Dashboard() {
  const history = useHistory();
  const { currentUser, isLoading: authLoading } = useAuth();

  useEffect(() => {
    !authLoading && !currentUser && history.push('/signin/');
  }, [authLoading, currentUser, history]);

  if (!authLoading && currentUser)
    return (
      <Fragment>
        <NavBar />
        <Container>
          <HeadLine title={'Dashboard'} />
          <Filters />
          <FilterInfo />
          <DataGrid />
          <VizCard label={'Net Sales by Day'}>
            <SalesLineChart />
          </VizCard>
          <VizCard label={'Net Sales by Time of Day'}>
            <DataBarChart />
          </VizCard>
        </Container>
      </Fragment>
    );

  return (
    <Center h="240px">
      <CircularProgress isIndeterminate color="green.300" thickness="4px" />
    </Center>
  );
}
