import { Box } from '@chakra-ui/react';

export default function VizCard({ label, children }) {
  return (
    <Box borderWidth="1px" borderRadius="lg" my="6">
      <Box p="2">
        <Box fontWeight="semibold" isTruncated textAlign="center" fontSize="md">
          {label}
        </Box>
        <Box my="2">{children}</Box>
      </Box>
    </Box>
  );
}
