import { Box, Heading, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import Card from './Card';
import SignInForm from './SignInForm';

export default function SignIn() {
  return (
    <Box bg={useColorModeValue('gray.50', 'inherit')} minH="100vh" py="12">
      <Box maxW="md" mx="auto">
        <Heading textAlign="center" size="lg" mb="4">
          SignIn to Voosh
        </Heading>
        <Card>
          <SignInForm />
        </Card>
      </Box>
    </Box>
  );
}
