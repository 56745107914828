import { Center, CircularProgress } from '@chakra-ui/react';
import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Container from '../common/Container';
import HeadLine from '../common/HeadLine';
import NavBar from '../common/NavBar';
import SubHeadline from '../common/SubHeadline';
import ChannelBreakdown from './ChannelBreakdown';
import FilterInfo from './FilterInfo';
import Filters from './Filters';
import FinancialGrid from './FinancialGrid';

export default function Financials() {
  const history = useHistory();
  const { currentUser, isLoading: authLoading } = useAuth();

  useEffect(() => {
    !authLoading && !currentUser && history.push('/signin/');
  }, [authLoading, currentUser, history]);

  if (!authLoading && currentUser)
    return (
      <Fragment>
        <NavBar />
        <Container>
          <HeadLine title={'Financials'} />
          <Filters />
          <FilterInfo />
          <FinancialGrid />
          <SubHeadline title={`Channel Breakdown`} />
          <ChannelBreakdown />
        </Container>
      </Fragment>
    );

  return (
    <Center h="240px">
      <CircularProgress isIndeterminate color="green.300" thickness="4px" />
    </Center>
  );
}
