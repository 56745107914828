import {
  Alert,
  AlertDescription,
  Button,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Progress,
  Stack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { PasswordField } from './PasswordField';

export default function SignInForm(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [err, setErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { signIn } = useAuth();

  return (
    <chakra.form
      onSubmit={e => {
        e.preventDefault();
        setIsLoading(true);
        const p = signIn(email, password);
        p.then(() => {
          setErr('');
          setTimeout(() => {
            history.push('/');
          }, 600);
        })
          .catch(err => {
            const { message } = err;
            setErr(message);
          })
          .finally(() => setIsLoading(false));
      }}
      {...props}
    >
      <Stack spacing="6">
        {isLoading ? <Progress size="xs" isIndeterminate /> : null}
        {err.length > 0 && (
          <Alert status="error">
            <AlertDescription>{err}</AlertDescription>
          </Alert>
        )}
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            onChange={event => setEmail(event.target.value)}
            name="email"
            type="email"
            autoComplete="email"
            required
          />
        </FormControl>
        <PasswordField onChange={event => setPassword(event.target.value)} />
        <Button type="submit" colorScheme="orange" size="lg" fontSize="md">
          SignIn
        </Button>
      </Stack>
    </chakra.form>
  );
}
