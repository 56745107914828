import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from '@chakra-ui/react';

const currencyOptions = {
  style: 'currency',
  currency: 'INR',
};
const currencyFormatter = new Intl.NumberFormat('en-IN', currencyOptions);

export default function ZomatoBreakDown() {
  return (
    <Table variant="simple">
      <TableCaption>Zomato Financial Breakdown</TableCaption>
      <Thead>
        <Tr>
          <Th>Deductions</Th>

          <Th>Amount</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Commisions</Td>

          <Td color="red">{currencyFormatter.format(1659.37)}</Td>
        </Tr>
        {/* <Tr>
          <Td>Discount on Platform Service Fee</Td>

          <Td>{currencyFormatter.format(0.0)}</Td>
        </Tr> */}
        <Tr>
          <Td>Convenience Fee</Td>

          <Td color="red">{currencyFormatter.format(144.67)}</Td>
        </Tr>
        {/* <Tr>
          <Td>Zomato Fee Tax</Td>

          <Td color="red">{currencyFormatter.format(324.62)}</Td>
        </Tr> */}
        {/* <Tr>
          <Td>Merchant Cancellation Charges</Td>

          <Td color="red">{currencyFormatter.format(0.0)}</Td>
        </Tr> */}
        {/* <Tr>
          <Td>Centre Service Fee</Td>

          <Td color="red">{currencyFormatter.format(0.0)}</Td>
        </Tr> */}
        <Tr>
          <Td>Total Service Fee Taxes</Td>

          <Td color="red">{currencyFormatter.format(324.62)}</Td>
        </Tr>
        <Tr>
          <Td>TCS</Td>

          <Td color="red">{currencyFormatter.format(30.46)}</Td>
        </Tr>
        <Tr>
          <Td>TDS</Td>

          <Td color="red">{currencyFormatter.format(30.46)}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
