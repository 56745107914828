import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const data = [
  {
    name: '11am',
    june: 4896.14,
    pv: 4800,
    amt: 2181,
  },
  {
    name: '12pm',
    june: 11870.21,
    pv: 3800,
    amt: 2500,
  },
  {
    name: '1pm',
    june: 18199.43,
    pv: 4300,
    amt: 2100,
  },
  {
    name: '2pm',
    june: 10663.33,
    pv: 2400,
    amt: 100,
  },
  {
    name: '3pm',
    june: 4299.91,
    pv: 1398,
    amt: 10,
  },
  {
    name: '7pm',
    june: 11296.79,
    pv: 3800,
    amt: 2500,
  },
  {
    name: '8pm',
    june: 15576.12,
    pv: 4300,
    amt: 2100,
  },
  {
    name: '9pm',
    june: 17989.16,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '10pm',
    june: 9116.51,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '11pm',
    june: 1115.04,
    pv: 9800,
    amt: 2290,
  },
];

const CustomizedYAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize={12} x={0} y={0} dy={4} textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize={12} x={0} y={0} dy={10} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

export default function DataBarChart() {
  return (
    <ResponsiveContainer aspect={1.5}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 5, right: 10, left: -10, bottom: 0 }}
      >
        <XAxis type="number" tick={<CustomizedAxisTick />} />
        <YAxis type="category" dataKey="name" tick={<CustomizedYAxisTick />} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Bar dataKey="june" fill="#FCAE7E" />
      </BarChart>
    </ResponsiveContainer>
  );
}
