import { Button, Stack } from '@chakra-ui/react';

export default function Filters() {
  return (
    <Stack direction="row" spacing={2} align="center" pt="6">
      <Button colorScheme="teal">June</Button>
      <Button colorScheme="teal" variant="outline">
        This Month
      </Button>
    </Stack>
  );
}
