import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from '@chakra-ui/react';

const currencyOptions = {
  style: 'currency',
  currency: 'INR',
};
const currencyFormatter = new Intl.NumberFormat('en-IN', currencyOptions);

export default function SwiggyBreakDown() {
  return (
    <Table variant="simple">
      <TableCaption>Swiggy Financial Breakdown</TableCaption>
      <Thead>
        <Tr>
          <Th>Deductions</Th>

          <Th>Amount</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Platform Service Fee</Td>

          <Td color="red">{currencyFormatter.format(23036.5)}</Td>
        </Tr>
        <Tr>
          <Td>Discount on Platform Service Fee</Td>

          <Td>{currencyFormatter.format(0.0)}</Td>
        </Tr>
        <Tr>
          <Td>Collection Charges</Td>

          <Td color="red">{currencyFormatter.format(0.0)}</Td>
        </Tr>
        <Tr>
          <Td>Access Charges</Td>

          <Td color="red">{currencyFormatter.format(0.0)}</Td>
        </Tr>
        <Tr>
          <Td>Merchant Cancellation Charges</Td>

          <Td color="red">{currencyFormatter.format(0.0)}</Td>
        </Tr>
        <Tr>
          <Td>Centre Service Fee</Td>

          <Td color="red">{currencyFormatter.format(0.0)}</Td>
        </Tr>
        <Tr>
          <Td>Total Service Fee Taxes</Td>

          <Td color="red">{currencyFormatter.format(4146.74)}</Td>
        </Tr>
        <Tr>
          <Td>TCS</Td>

          <Td color="red">{currencyFormatter.format(921.54)}</Td>
        </Tr>
        <Tr>
          <Td>TDS</Td>

          <Td color="red">{currencyFormatter.format(920.23)}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
