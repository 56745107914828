import { SimpleGrid } from '@chakra-ui/react';
import DataCard from '../common/DataCard';
import DataCardWithInfo from '../common/DataCardWithInfo';

import SwiggyBreakDown from './SwiggyBreakDown';

const currencyOptions = {
  style: 'currency',
  currency: 'INR',
};
const currencyFormatter = new Intl.NumberFormat('en-IN', currencyOptions);

const grossRevenue = currencyFormatter.format(103847.68);
const netReceivables = currencyFormatter.format(72664.31);
const totalDeductions = currencyFormatter.format(31183.37);

export default function FinancialGrid() {
  return (
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2} my="4">
      <DataCardWithInfo
        label={'Gross Revenue'}
        value={grossRevenue}
        desc={`Calculated for Delivered Orders Inclusive of GST Liabilities`}
      />
      <DataCardWithInfo
        label={'Deductions'}
        value={totalDeductions}
        desc={`Inclusive of Platform Charges & Service Fee Taxes`}
      />
      <DataCardWithInfo
        label={'Net Receivable'}
        value={netReceivables}
        desc={`Inclusive of TDS TCS Platform Charges & Deductions`}
      />
    </SimpleGrid>
  );
}
