import { SimpleGrid } from '@chakra-ui/react';
import DataCardWithInfo from '../common/DataCardWithInfo';

const currencyOptions = {
  style: 'currency',
  currency: 'INR',
};
const currencyFormatter = new Intl.NumberFormat('en-IN', currencyOptions);
const netPayout = currencyFormatter.format(66960.5);
const grossRevenue = currencyFormatter.format(95984.75);

const swiggyServiceFee = currencyFormatter.format(2000.0);
const orderLevelAdjustments = currencyFormatter.format(0);

export default function SwiggyDataGrid() {
  return (
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2} my="4">
      <DataCardWithInfo
        label={'Delivered Order(s)'}
        value={463}
        desc={`Total Orders Successfully Delivered by Swiggy`}
      />
      <DataCardWithInfo
        label={'Merchant Cancelled Orders'}
        value={1}
        desc={`Total Orders Cancelled by Merchant`}
      />
      <DataCardWithInfo
        label={'Gross Revenue'}
        value={grossRevenue}
        desc={`Inclusive of GST liability of Merchant`}
      />
      <DataCardWithInfo
        label={'Net Payout'}
        value={netPayout}
        desc={`Inclusive of TDS TCS Platform Charges & Deductions (see breakdown)`}
      />
      {/* <DataCardWithInfo
        label={'Net Payout'}
        value={netPayout}
        desc={`Calculated after deductions like TDS, TCS, Order Level Adjustments and Total Service Fee`}
      />
      <DataCardWithInfo
        label={'Total Customer Payable'}
        value={customerPayable}
        desc={`Sum of Net Bill Value after Taxes & Discount`}
      />
      <DataCardWithInfo
        label={'Total Service Fee'}
        value={swiggyServiceFee}
        desc={`Sum of Total Swiggy Service Fee after Taxes including CESS`}
      />
      <DataCard
        label={'Order Level Adjustments'}
        value={orderLevelAdjustments}
      /> */}
    </SimpleGrid>
  );
}
