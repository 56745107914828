import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const data = [
  {
    name: 'Mon',
    june: 10518.45,
    lastWeek: 0,
    amt: 2400,
  },
  {
    name: 'Tue',
    june: 17644.22,
    lastWeek: 0,
    amt: 2210,
  },
  {
    name: 'Wed',
    june: 16147.25,
    lastWeek: 0,
    amt: 2290,
  },
  {
    name: 'Thu',
    june: 12005.2,
    lastWeek: 0,
    amt: 2000,
  },
  {
    name: 'Fri',
    june: 20742.45,
    lastWeek: 0,
    amt: 2181,
  },
  {
    name: 'Sat',
    june: 8010.65,
    lastWeek: 0,
    amt: 2500,
  },
  {
    name: 'Sun',
    june: 19954.42,
    lastWeek: 0,
    amt: 2100,
  },
];

const CustomizedLabel = ({ x, y, stroke, value }) => {
  return (
    <text x={x} y={y} dy={-8} fill={stroke} fontSize={12} textAnchor="middle">
      {value}
    </text>
  );
};

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize={12}
        x={0}
        y={0}
        dy={10}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize={12}
        x={0}
        y={0}
        dy={10}
        textAnchor="end"
        fill="#666"
        transform="rotate(-25)"
      >
        {payload.value}
      </text>
    </g>
  );
};

export default function SalesLineChart() {
  return (
    <ResponsiveContainer aspect={2}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 10, left: -10, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
        <YAxis tick={<CustomizedYAxisTick />} />
        <Tooltip />
        <Legend />
        <Line
          connectNulls
          type="monotone"
          dataKey="june"
          stroke="#82ca9d"
          label={CustomizedLabel}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
