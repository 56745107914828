import { SimpleGrid } from '@chakra-ui/react';
import DataCard from './DataCard';
import DataCardWithUnit from './DataCardWithUnit';

const currencyOptions = {
  style: 'currency',
  currency: 'INR',
};
const currencyFormatter = new Intl.NumberFormat('en-IN', currencyOptions);
const netSales = currencyFormatter.format(103444.5);

const aov = currencyFormatter.format(202.83);

export default function DataGrid() {
  return (
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2} my="4">
      <DataCard label={'Net Sales'} value={netSales} />
      <DataCard label={'Average Order Value'} value={aov} />
      <DataCard label={'Total Orders Received'} value={510} />
      <DataCard label={'Total Cancelled Orders'} value={9} />
      <DataCardWithUnit label={'Avg Preparation Time'} value={12} unit="min" />
    </SimpleGrid>
  );
}
