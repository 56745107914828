import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';

export default function BreakdownItem({ children, channelLogo }) {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box flex="1">{channelLogo}</Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={2}>{children}</AccordionPanel>
    </AccordionItem>
  );
}
