import { Accordion, Box } from '@chakra-ui/react';
import swiggyLogo from '../../static/swiggy.svg';
import zomatoLogo from '../../static/zomato.webp';
import ChannelLogo from '../common/ChannelLogo';
import BreakdownItem from './BreakdownItem';
import SwiggyBreakDown from './SwiggyBreakDown';
import SwiggyDataGrid from './SwiggyDataGrid';
import ZomatoBreakDown from './ZomatoBreakDown';
import ZomatoDataGrid from './ZomatoDataGrid';

export default function ChannelBreakdown() {
  const SwiggyLogo = <ChannelLogo src={swiggyLogo} />;
  const ZomatoLogo = <ChannelLogo src={zomatoLogo} height="24px" />;
  return (
    <Box my="6">
      <Accordion defaultIndex={[0]} allowMultiple>
        <BreakdownItem channelLogo={SwiggyLogo}>
          <SwiggyDataGrid />
          <SwiggyBreakDown />
        </BreakdownItem>
        <BreakdownItem channelLogo={ZomatoLogo}>
          <ZomatoDataGrid />
          <ZomatoBreakDown />
        </BreakdownItem>
      </Accordion>
    </Box>
  );
}
